import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export default function FullScreenSection({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <section className={twMerge('h-full min-h-screen w-screen', className)}>
      {children}
    </section>
  );
}
