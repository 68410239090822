import { useParams } from "react-router-dom";
import HeadingText from "../components/atoms/HeadingText";
import useUserInfoById from "../hooks/api/useUserInfoById";
import FullScreenSection from "../components/atoms/FullScreenSection";
import Spinner from "../components/atoms/Spinner";
import { useEffect } from "react";
import useAnimatedUnmount from "../hooks/useAnimatedUnmount";
import { twMerge } from "tailwind-merge";
import InitiativeCharacterList from "../components/organisms/InitiativeCharacterList";

export default function InitiativePage() {
  const { id } = useParams();

  const { userInfo, isLoadingUser, isError } = useUserInfoById(id);

  const { shouldRender, animatedElementRef } = useAnimatedUnmount(
    !isLoadingUser && userInfo.isInitiativeVisible
  );

  useEffect(() => {
    document.body.classList.add("bg-transparent");

    return () => {
      document.body.classList.remove("bg-transparent");
    };
  });

  if (isError) {
    return (
      <FullScreenSection className="flex justify-center items-center bg-transparent">
        <HeadingText type="h1" color="indigo">
          Um erro ocorreu! Verifique a aplicação.
        </HeadingText>
      </FullScreenSection>
    );
  }

  if (isLoadingUser) {
    return (
      <FullScreenSection className="flex justify-center items-center bg-transparent">
        <Spinner size="large" color="indigo" />
      </FullScreenSection>
    );
  }

  return (
    <FullScreenSection>
      {shouldRender && (
        <div
          className={twMerge(
            "fixed bottom-10 w-[1700px] left-1/2 -translate-x-1/2 h-[196px] bg-black rounded-3xl bg-opacity-70",
            !isLoadingUser && userInfo.isInitiativeVisible
              ? "animate-fadeIn"
              : "animate-fadeOut"
          )}
          ref={animatedElementRef}
        >
          <div className="py-4 px-8">
            <InitiativeCharacterList
              userInfo={userInfo}
              characters={userInfo.initiativeCharacters}
              selectedId={userInfo.activeIndex}
            />
          </div>
        </div>
      )}
    </FullScreenSection>
  );
}
