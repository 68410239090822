import { Form, Formik, FormikHelpers } from "formik";
import FormField from "../molecules/FormField";
import Button from "../molecules/Button";
import FormFieldContainer from "../atoms/FormFieldContainer";
import { LoginPayload } from "../../service/AuthService";
import { object, string } from "yup";

type HandleSubmitFunction = (
  values: LoginPayload,
  helpers: FormikHelpers<LoginPayload>
) => void;

export type SignInFormProps = {
  onSubmit: HandleSubmitFunction;
};

const LoginSchema = object().shape({
  email: string()
    .email("Precisa ser um email válido")
    .required("O email é um campo obrigatório"),
  password: string()
    .min(3, "A senha precisa ter no mínimo 3 caracteres")
    .required("A senha é um campo obrigatório"),
});

export default function SignInForm({ onSubmit }: SignInFormProps) {
  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={onSubmit}
      validationSchema={LoginSchema}
      validateOnBlur
      validateOnChange
    >
      {({ isSubmitting, errors, touched, isValid, dirty }) => (
        <Form className="flex flex-col gap-6">
          <FormFieldContainer>
            <FormField
              name="email"
              label="E-Mail"
              type="email"
              errors={errors}
              touched={touched}
            />
            <FormField
              name="password"
              label="Senha"
              type="password"
              errors={errors}
              touched={touched}
            />
          </FormFieldContainer>
          <Button
            loading={isSubmitting}
            className="w-full"
            disabled={!dirty || !isValid}
            type="submit"
          >
            Sign in
          </Button>
        </Form>
      )}
    </Formik>
  );
}
