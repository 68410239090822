import * as RdxDropdownMenu from "@radix-ui/react-dropdown-menu";
import { ChildrenWithClassProp } from ".";
import { twMerge } from "tailwind-merge";

export default function DropdownMenuTrigger({
  children,
  className,
}: ChildrenWithClassProp) {
  return (
    <RdxDropdownMenu.Trigger
      asChild
      className={twMerge("outline-none", className)}
    >
      {children}
    </RdxDropdownMenu.Trigger>
  );
}
