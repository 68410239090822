import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import IconButton from "../atoms/IconButton";
import InitiativeCharacter from "../../types/InitiativeCharacter";
import BodyText from "../atoms/BodyText";
import { useSwiperReactive } from "../../hooks/useSwiperReactive";

type Props = {
  initiativeCharacters: InitiativeCharacter[];
};

export default function InitiativeNavigation({ initiativeCharacters }: Props) {
  const swiper = useSwiperReactive();

  const currentCharacter =
    initiativeCharacters.length > 0
      ? initiativeCharacters[swiper.realIndex]
      : undefined;

  function handleNext() {
    if (swiper.realIndex + 1 === initiativeCharacters.length) {
      swiper.slideTo(0);
      return;
    }

    swiper.slideNext();
  }

  function handlePrev() {
    if (swiper.realIndex === 0) {
      swiper.slideTo(initiativeCharacters.length);
      return;
    }

    swiper.slidePrev();
  }

  return (
    <div className="mb-4 gap-2 items-center flex justify-center mt-8">
      <IconButton
        onClick={handlePrev}
        disabled={initiativeCharacters.length <= 1}
      >
        <ChevronLeftIcon className="h-6 w-6" />
      </IconButton>
      <BodyText color="slate" size="large">
        Turno de: {currentCharacter ? currentCharacter.name : "Ninguém"}
      </BodyText>
      <IconButton
        onClick={handleNext}
        disabled={initiativeCharacters.length <= 1}
      >
        <ChevronRightIcon className="h-6 w-6" />
      </IconButton>
    </div>
  );
}
