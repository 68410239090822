import { SparklesIcon } from "@heroicons/react/24/solid";
import { twMerge } from "tailwind-merge";

type Props = {
  size?: "normal" | "large" | "small";
};

export default function Logo({ size }: Props) {
  let imageSize = "";
  let textSize = "";

  switch (size) {
    case "large":
      imageSize = "h-9 w-9";
      textSize = "text-3xl";
      break;
    case "small":
      imageSize = "h-6 w-6";
      textSize = "text-lg";
      break;
    default:
      imageSize = "h-8 w-8";
      textSize = "text-2xl";
      break;
  }

  return (
    <div className="flex cursor-default items-center justify-center gap-3">
      <SparklesIcon className={twMerge("text-indigo-600", imageSize)} />
      <h2 className={twMerge("font-bold text-white", textSize)}>rpgplus</h2>
    </div>
  );
}
