import axios, { CreateAxiosDefaults } from 'axios';
import useAuthStore from '../stores/useAuthStore';
import env from '../libs/env';

export default function useAuthenticatedAxios() {
  const accessToken = useAuthStore((state) => state.accessToken);

  const config: CreateAxiosDefaults = {
    baseURL: env.API_URL,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const client = axios.create(config);

  return client;
}
