import * as RdxDropdownMenu from "@radix-ui/react-dropdown-menu";
import { ChildrenWithClassProp } from ".";
import { twMerge } from "tailwind-merge";

interface DropdownMenuContentProps extends ChildrenWithClassProp {
  side?: "top" | "bottom";
  sideOffset?: number;
}

export default function DropdownMenuContent({
  children,
  className,
  sideOffset,
  side = "top",
}: DropdownMenuContentProps) {
  return (
    <RdxDropdownMenu.Portal>
      <RdxDropdownMenu.Content
        sideOffset={sideOffset}
        side={side}
        className={twMerge(
          "rounded-2xl p-2 space-y-2 bg-gray-800 shadow-menu z-10",
          "data-[side=top]:data-[state=open]:animate-slideDownAndFade data-[side=bottom]:data-[state=open]:animate-slideUpAndFade",
          "data-[side=top]:data-[state=closed]:animate-slideDownAndFadeOut data-[side=bottom]:data-[state=cloud]:animate-slideUpAndFadeOut",
          className
        )}
      >
        {children}
      </RdxDropdownMenu.Content>
    </RdxDropdownMenu.Portal>
  );
}
