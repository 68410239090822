import { useEffect } from 'react';
import useAnimatedList from '../../hooks/useAnimatedList';
import InitiativeCharacter from '../../types/InitiativeCharacter';
import InitiativeCharacterCard from '../molecules/InitiativeCharacterCard';
import User from '../../types/User';

type Props = {
  userInfo: User;
  characters: InitiativeCharacter[];
  selectedId: number;
};

export default function InitiativeCharacterList({
  userInfo,
  characters,
  selectedId,
}: Props) {
  const sortCharacters = (a: InitiativeCharacter, b: InitiativeCharacter) => {
    return b.diceResult - a.diceResult;
  };
  const sortedCharacters = characters.sort(sortCharacters);

  const { renderList, setItems, items, handleRemoveItem } =
    useAnimatedList<InitiativeCharacter>(sortedCharacters, selectedId, 7);

  useEffect(() => {
    console.log('Detectada mudança nos personagens...');

    const existIds = items.map((item) => item.id);

    existIds.forEach((id) => {
      if (!characters.some((character) => character.id === id)) {
        handleRemoveItem(id);
        console.log(`Personagem #${id} foi removido.`);
      }
    });

    characters.forEach((character) => {
      if (!existIds.includes(character.id)) {
        console.log(
          `Personagem ${character.name}#${character.id} foi adicionado.`
        );
        setItems((state) => [...state, character].sort(sortCharacters));
      }
    });
  }, [characters, handleRemoveItem, items, setItems]);

  return (
    <div className="flex gap-12 items-center justify-center">
      {renderList((character, { isLeaving, animatedRef }) => (
        <InitiativeCharacterCard
          userInfo={userInfo}
          key={character.id}
          isSelected={selectedId === character.id}
          isLeaving={isLeaving}
          animatedRef={animatedRef}
          character={character}
        />
      ))}
    </div>
  );
}
