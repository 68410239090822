import { Swiper, SwiperSlide } from "swiper/react";

import ContentCard from "../atoms/ContentCard";
import HeadingText from "../atoms/HeadingText";
import CharacterCard from "../molecules/CharacterCard";
import BodyText from "../atoms/BodyText";
import IconButton from "../atoms/IconButton";
import { useEffect, useMemo, useState } from "react";
import Button from "../molecules/Button";
import { TrashIcon } from "@heroicons/react/24/solid";
import useAuthenticatedAxios from "../../hooks/useAuthenticatedAxios";
import { useQueryClient } from "@tanstack/react-query";
import InitiativeCharacter from "../../types/InitiativeCharacter";

import "swiper/css";
import InitiativeNavigation from "../molecules/InitiativeNavigation";

type Props = {
  isShowingInitiative?: boolean;
  initiative: InitiativeCharacter[];
  activeCharacter: number;
  isLoading: boolean;
  onModalOpen: (name: string, imageUrl: string) => void;
  onConfirmModalOpen: (text: string, onSubmit: () => Promise<void>) => void;
};

export default function InitiativeCard({
  initiative,
  isShowingInitiative,
  onModalOpen,
  onConfirmModalOpen,
  activeCharacter,
  isLoading,
}: Props) {
  const axios = useAuthenticatedAxios();
  const queryClient = useQueryClient();

  const [isTogglingVisibility, setTogglingVisibility] = useState(false);
  const [isCleaning, setCleaning] = useState(false);

  const characterIndex = useMemo(
    () =>
      initiative
        .sort((a, b) => b.diceResult - a.diceResult)
        .findIndex((obj) => obj.id === activeCharacter),
    [activeCharacter, initiative]
  );

  const sortedInitiative = initiative.sort(
    (a, b) => b.diceResult - a.diceResult
  );

  async function handleCharacterUpdate(newIndex: number) {
    if (initiative.length === 0) return;

    const character = initiative.sort((a, b) => b.diceResult - a.diceResult)[
      newIndex
    ];

    axios.put(`/users/`, { activeIndex: character.id });
  }

  async function handleToggleVisibility() {
    setTogglingVisibility(true);
    await axios.put(`/users/`, { isInitiativeVisible: !isShowingInitiative });

    queryClient.invalidateQueries();
    setTogglingVisibility(false);
  }

  async function handleRemoveFromInitiative(character: InitiativeCharacter) {
    const onSubmit = async () => {
      await axios.delete(`/initiatives/${character.id}`);

      queryClient.invalidateQueries();
    };

    onConfirmModalOpen(
      `Você tem certeza que deseja remover o personagem <b class="text-indigo-400">${character.name}</b> da iniciativa?`,
      onSubmit
    );
  }

  async function handleCleanInitiative() {
    const onSubmit = async () => {
      setCleaning(true);
      for (let i = 0; i < initiative.length; i++) {
        const obj = initiative[i];

        await axios.delete(`/initiatives/${obj.id}`);
      }
      await axios.put(`/users/`, {
        activeIndex: 0,
        isInitiativeVisible: false,
      });

      queryClient.invalidateQueries();
      setCleaning(false);
    };

    onConfirmModalOpen(
      "Você tem certeza que deseja limpar a iniciativa?",
      onSubmit
    );
  }

  return (
    <div>
      <ContentCard>
        <Swiper
          spaceBetween={16}
          slidesPerView={5.3}
          initialSlide={characterIndex}
          onSlideChange={(swiper: any) =>
            handleCharacterUpdate(swiper.realIndex)
          }
          centeredSlides
        >
          <header slot="container-start" className="flex justify-between mb-8">
            <HeadingText color="slate" weight="semibold">
              Iniciativa atual
            </HeadingText>
            <div className="flex gap-2">
              <Button
                color={isShowingInitiative ? "secondary" : "default"}
                onClick={handleToggleVisibility}
                loading={isTogglingVisibility}
                disabled={!isShowingInitiative && initiative.length === 0}
              >
                {isShowingInitiative ? "Ocultar" : "Exibir"}
              </Button>
              <Button
                color="destructive"
                onClick={handleCleanInitiative}
                disabled={initiative.length === 0}
                loading={isCleaning}
              >
                Limpar
              </Button>
              <Button onClick={() => onModalOpen("", "")}>
                Inserir personagem
              </Button>
            </div>
          </header>
          <div>
            {initiative
              .sort((a, b) => b.diceResult - a.diceResult)
              .map((character) => (
                <SwiperSlide key={character.id}>
                  {({ isActive }) => (
                    <CharacterCard
                      className={
                        isActive ? "bg-indigo-800 hover:bg-indigo-700" : ""
                      }
                      imageUrl={character.imageUrl}
                    >
                      <BodyText size="large" color="slate">
                        {character.name}
                      </BodyText>
                      <IconButton
                        color="destructive"
                        onClick={() => handleRemoveFromInitiative(character)}
                      >
                        <TrashIcon className="w-6 h-6" />
                      </IconButton>
                      <BodyText size="large" color="darkSlate">
                        {character.diceResult}
                      </BodyText>
                    </CharacterCard>
                  )}
                </SwiperSlide>
              ))}
            {initiative.length === 0 && (
              <div className="flex justify-center">
                <div className="w-1/5 h-36 text-slate-200 border-indigo-600 border-dashed border-2 rounded-2xl transition-all flex justify-center items-center">
                  Sem nenhum personagem na iniciativa
                </div>
              </div>
            )}
          </div>
          <footer>
            <InitiativeNavigation initiativeCharacters={sortedInitiative} />
          </footer>
        </Swiper>
      </ContentCard>
    </div>
  );
}
