import parseUserInitials from "../../libs/parseUserInitials";

export default function UserInitials({ userName }: { userName: string }) {
  return (
    <div className="flex h-12 w-12 items-center justify-center rounded-full bg-indigo-800">
      <h3 className="text-sm font-medium tracking-tighter text-white">
        {parseUserInitials(userName)}
      </h3>
    </div>
  );
}
