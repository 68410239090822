import { useQuery } from "@tanstack/react-query";
import useAuthenticatedAxios from "../useAuthenticatedAxios";
import User from "../../types/User";

export default function useUserInfo() {
  const axios = useAuthenticatedAxios();

  function getUserInfo() {
    return axios.get("/users/me");
  }

  const { data, isError, isLoading } = useQuery({
    queryKey: ["users", "me"],
    queryFn: getUserInfo,
  });

  return {
    userInfo: (data?.data as User) || {},
    isError,
    isLoadingUser: isLoading,
  };
}
