import {
  ReactNode,
  Ref,
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

type Id = number | string;

interface ItemWIthId {
  id: Id;
}

export default function useAnimatedList<Type extends ItemWIthId>(
  initialValue: Type[] = [],
  selectedId: Id,
  maxSize: number = 7
) {
  type RenderItemFunction = (
    item: Type,
    helpers: { isLeaving: boolean; animatedRef: Ref<any> }
  ) => ReactNode;

  const [items, setItems] = useState<Type[]>(initialValue);
  const [pendingRemovalItemsIds, setPendingRemovalItemsIds] = useState<Id[]>(
    []
  );

  /**
   * Animated Refs
   */

  const animatedRefs = useRef(new Map());
  const animationEndListeners = useRef(new Map());

  const getAnimatedRef = useCallback((itemId: Id) => {
    let animatedRef = animatedRefs.current.get(itemId);
    if (!animatedRef) {
      animatedRef = createRef();
      animatedRefs.current.set(itemId, animatedRef);
    }

    return animatedRef;
  }, []);

  /**
   * Functions
   */

  const handleRemoveItem = useCallback((itemId: Id) => {
    setPendingRemovalItemsIds((prevState) => [...prevState, itemId]);
  }, []);

  const handleAnimationEnd = useCallback((itemId: Id) => {
    const removeListener = animationEndListeners.current.get(itemId);
    removeListener();

    animatedRefs.current.delete(itemId);
    animationEndListeners.current.delete(itemId);

    setItems((prevState) => prevState.filter((item) => item.id !== itemId));
    setPendingRemovalItemsIds((prevState) =>
      prevState.filter((id) => id !== itemId)
    );
  }, []);

  const renderList = useCallback(
    (renderItem: RenderItemFunction, listToRender?: Type[]) => {
      const itemsList = listToRender || items;

      const selectedIndex = itemsList.findIndex(
        (item) => item.id === selectedId
      );

      let start = Math.max(0, selectedIndex - Math.floor(maxSize / 2));
      let end = start + maxSize;

      // Adjust start and end to ensure maxSize items are rendered when possible
      if (end > itemsList.length) {
        end = itemsList.length;
        start = Math.max(0, end - maxSize);
      }

      const limitedItems = itemsList.slice(start, end);

      return limitedItems.map((item) => {
        const isLeaving = pendingRemovalItemsIds.includes(item.id);

        const animatedRef = getAnimatedRef(item.id);

        return renderItem(item, { isLeaving, animatedRef });
      });
    },
    [getAnimatedRef, items, pendingRemovalItemsIds, selectedId, maxSize]
  );

  /**
   * Hooks
   */

  useEffect(() => {
    pendingRemovalItemsIds.forEach((itemId) => {
      const animatedRef = animatedRefs.current.get(itemId);
      const animatedElement = animatedRef?.current;

      const alreadyHasListener = animationEndListeners.current.has(itemId);

      if (animatedElement && !alreadyHasListener) {
        const onAnimationEnd = () => {
          handleAnimationEnd(itemId);
        };

        const removeListener = () => {
          animatedElement.removeEventListener('animationend', onAnimationEnd);
        };

        animationEndListeners.current.set(itemId, removeListener);
        animatedElement.addEventListener('animationend', onAnimationEnd);
      }
    });
  }, [handleAnimationEnd, pendingRemovalItemsIds]);

  useEffect(() => {
    const removeListeners = animationEndListeners.current;

    return () => {
      removeListeners.forEach((removeListener) => removeListener());
    };
  }, []);

  return {
    items,
    setItems,
    renderList,
    handleRemoveItem,
  };
}
