import { twMerge } from "tailwind-merge";
import useAnimatedUnmount from "../../hooks/useAnimatedUnmount";
import User from "../../types/User";

interface Props {
  shouldShow: boolean;
  userInfo: User;
}

export default function LogoSymbol({ shouldShow, userInfo }: Props) {
  const { shouldRender, animatedElementRef } = useAnimatedUnmount(shouldShow);

  if (!shouldRender) {
    return <></>;
  }

  return (
    <img
      ref={animatedElementRef}
      src={userInfo.rpgLogo}
      alt="Simbolo da Logo"
      className={twMerge(
        "absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-10",
        shouldShow ? "animate-fadeIn" : "animate-fadeOut"
      )}
    />
  );
}
