import { styled } from "react-tailwind-variants";

const BodyText = styled("p", {
  variants: {
    size: {
      large: "text-lg tracking-tight",
      normal: "text-base tracking-tight",
      small: "text-sm",
    },
    weight: {
      bold: "font-bold",
      medium: "font-medium",
      regular: "font-normal",
    },
    color: {
      darkSlate: "text-slate-300",
      slate: "text-slate-200",
      indigo: "text-indigo-500",
    },
  },
  defaultVariants: {
    size: "normal",
    weight: "regular",
    color: "darkSlate",
  },
});

export default BodyText;
