import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import LoginPage from "../pages/Login";
import HomePage from "../pages/Home";
import RegisterPage from "../pages/Register";
import InitiativePage from "../pages/Initiative";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  {
    path: "/initiative/:id",
    element: <InitiativePage />,
  },
];

const router = createBrowserRouter(routes);

export default function Router() {
  return <RouterProvider router={router} />;
}
