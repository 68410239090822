import { twMerge } from "tailwind-merge";
import InitiativeCharacter from "../../types/InitiativeCharacter";
import LogoSymbol from "../atoms/LogoSymbol";
import { Ref } from "react";
import User from "../../types/User";

interface Props {
  userInfo: User;
  character: InitiativeCharacter;
  isSelected: boolean;
  isLeaving?: boolean;
  animatedRef?: Ref<any>;
}

export default function InitiativeCharacterCard({
  userInfo,
  character,
  isSelected,
  animatedRef,
  isLeaving,
}: Props) {
  return (
    <div
      className={twMerge(
        "relative",
        isLeaving ? "animate-fadeOut" : "animate-fadeIn"
      )}
      ref={animatedRef}
    >
      <img
        src={character.imageUrl}
        alt={character.name}
        className={twMerge(
          "z-50 h-[175px] relative overflow-hidden transition-all",
          isSelected && "selected-image"
        )}
      />
      <LogoSymbol userInfo={userInfo} shouldShow={isSelected} />
    </div>
  );
}
