/* eslint-disable import/no-anonymous-default-export */
import HttpClient from "../libs/HttpClient";

export type LoginPayload = {
  email: string;
  password: string;
};

class AuthService {
  async login(payload: LoginPayload): Promise<string> {
    const response = await HttpClient.post("/auth", payload);

    return response.data.token;
  }
}

export default new AuthService();
