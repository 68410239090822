import { FormikHelpers } from "formik";
import FullScreenSection from "../components/atoms/FullScreenSection";
import FormHeader from "../components/molecules/FormHeader";
import useAuthStore from "../stores/useAuthStore";
import { useNavigate } from "react-router-dom";
import Logo from "../components/atoms/Logo";
import RegisterForm from "../components/organisms/RegisterForm";
import UsersService, { RegisterPayload } from "../service/UsersService";

export default function RegisterPage() {
  const login = useAuthStore((store) => store.login);
  const navigate = useNavigate();

  async function handleSubmit(
    values: RegisterPayload,
    { setFieldError }: FormikHelpers<RegisterPayload>
  ) {
    try {
      await UsersService.register(values);

      const logged = await login(values);

      if (logged) {
        navigate("/");
      }
    } catch (error) {
      setFieldError("email", "Already exists an account with this email");
      return;
    }
  }

  return (
    <FullScreenSection className="flex justify-center items-center">
      <div className="fixed top-8">
        <Logo size="normal" />
      </div>
      <div className="flex w-full flex-col items-center justify-center gap-12">
        <FormHeader
          bodyText="Já tem uma conta?"
          linkText="Entrar agora"
          linkHref="/login"
        >
          Criar a sua conta
        </FormHeader>
        <RegisterForm onSubmit={handleSubmit} />
      </div>
    </FullScreenSection>
  );
}
