import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import AuthService, { LoginPayload } from "../service/AuthService";
import { bake_cookie, read_cookie, delete_cookie } from "sfcookies";

type AuthStore = {
  accessToken: string | null;
  login: (payload: LoginPayload) => Promise<boolean>;
  logout: () => void;
};

const useAuthStore = create<AuthStore>()(
  persist(
    (set) => ({
      accessToken: null,
      login: async (payload: LoginPayload) => {
        try {
          const data = await AuthService.login(payload);

          set({ accessToken: data });
        } catch (error) {
          console.error(error);

          return false;
        }

        return true;
      },
      logout: () => {
        set(() => ({ accessToken: null }));
      },
    }),
    {
      name: "rpgplus-auth",
      storage: createJSONStorage(() => ({
        getItem(name) {
          return read_cookie(name) as string;
        },
        setItem(name, value) {
          bake_cookie(name, value);
        },
        removeItem(name) {
          delete_cookie(name);
        },
      })),
    }
  )
);

export default useAuthStore;
