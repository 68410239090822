import { Swiper, SwiperSlide } from "swiper/react";

import UserPreset from "../../types/UserPreset";
import ContentCard from "../atoms/ContentCard";
import HeadingText from "../atoms/HeadingText";

import "swiper/css";
import CharacterCard from "../molecules/CharacterCard";
import BodyText from "../atoms/BodyText";
import IconButton from "../atoms/IconButton";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import SliderNavigation from "../molecules/SliderNavigation";
import Button from "../molecules/Button";
import { TrashIcon } from "@heroicons/react/24/solid";
import useAuthenticatedAxios from "../../hooks/useAuthenticatedAxios";
import { useQueryClient } from "@tanstack/react-query";

type Props = {
  presets: UserPreset[];
  onModalOpen?: () => void;
  onInitiativeModalOpen: (name: string, imageUrl: string) => void;
  onConfirmModalOpen: (text: string, onSubmit: () => Promise<void>) => void;
};

export default function PresetsCard({
  presets,
  onModalOpen,
  onInitiativeModalOpen,
  onConfirmModalOpen,
}: Props) {
  const axios = useAuthenticatedAxios();
  const queryClient = useQueryClient();

  const [sliderState, setSliderState] = useState({
    isBeginning: true,
    isEnd: false,
  });

  function handleSlideChange(swiper: any) {
    setSliderState({ isBeginning: swiper.isBeginning, isEnd: swiper.isEnd });
  }

  function handleAddToInitiative(preset: UserPreset) {
    onInitiativeModalOpen(preset.name, preset.imageUrl);
  }

  async function handleDeletePreset(preset: UserPreset) {
    const onSubmit = async () => {
      await axios.delete(`/presets/${preset.id}`);

      queryClient.invalidateQueries();
    };

    onConfirmModalOpen(
      `Você tem certeza que deseja deletar a predefinição <b class="text-indigo-400">${preset.name}</b>?`,
      onSubmit
    );
  }

  return (
    <div>
      <ContentCard>
        <Swiper
          spaceBetween={16}
          slidesPerView={5.3}
          onSlideChange={handleSlideChange}
        >
          <header slot="container-start" className="flex justify-between">
            <HeadingText color="slate" weight="semibold">
              Suas predefinições
            </HeadingText>
            <div className="flex gap-2">
              <SliderNavigation
                isBeginning={sliderState.isBeginning}
                isEnd={sliderState.isEnd}
              />
              <Button onClick={onModalOpen}>Novo</Button>
            </div>
          </header>
          <div>
            {presets.map((preset) => (
              <SwiperSlide key={preset.id}>
                <CharacterCard imageUrl={preset.imageUrl}>
                  <BodyText size="large" color="slate">
                    {preset.name}
                  </BodyText>
                  <IconButton
                    color="indigo"
                    className=""
                    onClick={() => handleAddToInitiative(preset)}
                  >
                    <PlusIcon className="w-6 h-6" />
                  </IconButton>
                  <IconButton
                    color="destructive"
                    className=""
                    onClick={() => handleDeletePreset(preset)}
                  >
                    <TrashIcon className="w-6 h-6" />
                  </IconButton>
                </CharacterCard>
              </SwiperSlide>
            ))}
            {presets.length === 0 && (
              <SwiperSlide>
                <button
                  onClick={onModalOpen}
                  className="w-full h-36 text-slate-200 border-indigo-600 border-dashed border-2 rounded-2xl hover:bg-indigo-600 hover:text-white transition-all"
                >
                  Adicionar predefinição
                </button>
              </SwiperSlide>
            )}
          </div>
        </Swiper>
      </ContentCard>
    </div>
  );
}
