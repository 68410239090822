import { useQuery } from "@tanstack/react-query";
import useAuthenticatedAxios from "../useAuthenticatedAxios";

export default function useInitiative() {
  const axios = useAuthenticatedAxios();

  function queryFn() {
    return axios.get("/initiatives");
  }

  const { data, isError, isLoading } = useQuery({
    queryKey: ["initiative"],
    queryFn,
  });

  return {
    initiativeCharacters: data?.data || [],
    isError,
    isLoadingInitiative: isLoading,
  };
}
