export default function parseUserInitials(userName: string) {
  if (!userName) return '';

  const splitted = userName.split(' ');

  const initials: string[] = [];

  splitted.forEach((partOfName) => {
    initials.push(partOfName.charAt(0));
  });

  return initials.join('').toUpperCase();
}
