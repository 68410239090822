import Modal from "../../molecules/Modal";
import Button from "../../molecules/Button";
import BodyText from "../../atoms/BodyText";

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
  text: string;
};

export default function ConfirmModal({ open, onClose, text, onSubmit }: Props) {
  async function handleSubmit() {
    onSubmit();
    onClose();
  }

  return (
    <Modal open={open} onClose={onClose} title="Confirme essa ação">
      <BodyText
        className="mb-10"
        color="slate"
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <div className="flex w-full justify-between">
        <Button color="secondary" size="small" onClick={onClose}>
          Voltar
        </Button>
        <Button color="destructive" size="small" onClick={handleSubmit}>
          Confirmar
        </Button>
      </div>
    </Modal>
  );
}
