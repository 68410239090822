import { FormikHelpers } from "formik";
import FullScreenSection from "../components/atoms/FullScreenSection";
import FormHeader from "../components/molecules/FormHeader";
import SignInForm from "../components/organisms/SignInForm";
import { LoginPayload } from "../service/AuthService";
import useAuthStore from "../stores/useAuthStore";
import { useNavigate } from "react-router-dom";
import Logo from '../components/atoms/Logo';

export default function LoginPage() {
  const login = useAuthStore((store) => store.login);
  const navigate = useNavigate();

  async function handleSubmit(
    values: LoginPayload,
    { setFieldError }: FormikHelpers<LoginPayload>
  ) {
    const logged = await login(values);

    if (logged) {
      setTimeout(() => {
        navigate("/");
      }, 1500);
    } else {
      setFieldError("password", "Credenciais inválidas");
    }
  }

  return (
    <FullScreenSection className="flex justify-center items-center">
      <div className='fixed top-8'>
        <Logo size='normal' />
      </div>
      <div className="flex w-full flex-col items-center justify-center gap-12">
        <FormHeader
          bodyText="Não tem uma?"
          linkText="Criar agora"
          linkHref="/register"
        >
          Entrar na sua conta
        </FormHeader>
        <SignInForm onSubmit={handleSubmit} />
      </div>
    </FullScreenSection>
  );
}
