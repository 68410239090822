import * as RdxDropdownMenu from "@radix-ui/react-dropdown-menu";

import { twMerge } from "tailwind-merge";
import { ChildrenWithClassProp } from ".";

interface DropdownMenuItemProps extends ChildrenWithClassProp {
  onClick?: () => void;
}

export default function DropdownMenuItem({
  children,
  className,
  onClick,
}: DropdownMenuItemProps) {
  return (
    <RdxDropdownMenu.Item
      onSelect={onClick}
      className={twMerge(
        "min-h-[40px] outline-none flex gap-2 justify-between items-center px-4 py-2 z-50 text-sm text-slate-100 data-[highlighted]:bg-slate-700 rounded-2xl cursor-pointer transition-all",
        className
      )}
    >
      {children}
    </RdxDropdownMenu.Item>
  );
}
