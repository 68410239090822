import { useQuery } from "@tanstack/react-query";
import useAuthenticatedAxios from "../useAuthenticatedAxios";
import User from "../../types/User";
import InitiativeCharacter from "../../types/InitiativeCharacter";

interface UserWithInitiative extends User {
  initiativeCharacters: InitiativeCharacter[];
}

export default function useUserInfoById(id?: string): {
  userInfo: UserWithInitiative;
  isError: boolean;
  isLoadingUser: boolean;
} {
  const axios = useAuthenticatedAxios();

  function getUserInfo() {
    return axios.get(`/users/${id}`);
  }

  const { data, isError, isLoading } = useQuery({
    queryKey: ["users", id],
    queryFn: getUserInfo,
    refetchInterval: 1000,
  });

  return { userInfo: data?.data || {}, isError, isLoadingUser: isLoading };
}
