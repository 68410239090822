import { ReactNode } from "react";
import BodyText from "../atoms/BodyText";
import HeadingText from "../atoms/HeadingText";
import Logo from "../atoms/Logo";
import Button from "./Button";
import { Link } from "react-router-dom";

type Props = {
  linkText: string;
  linkHref: string;
  bodyText: string;
  children: ReactNode;
};

export default function FormHeader({
  linkHref,
  linkText,
  bodyText,
  children,
}: Props) {
  return (
    <div className="flex flex-col items-center">
      <HeadingText type="h2" weight="medium">
        {children}
      </HeadingText>
      <div className="flex items-center gap-1">
        <BodyText>{bodyText}</BodyText>
        <Link to={linkHref}>
          <Button color="ghost" type="button">
            {linkText}
          </Button>
        </Link>
      </div>
    </div>
  );
}
