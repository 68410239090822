import { ReactNode } from 'react';
import DropdownMenuRoot from './Root';
import DropdownMenuTrigger from './Trigger';
import DropdownMenuContent from './Content';
import DropdownMenuItem from './Item';

export type ChildrenWithClassProp = {
  children: ReactNode;
  className?: string;
};

export const DropdownMenu = {
  Root: DropdownMenuRoot,
  Trigger: DropdownMenuTrigger,
  Content: DropdownMenuContent,
  Item: DropdownMenuItem,
};
