import { useQueryClient } from "@tanstack/react-query";
import useAuthenticatedAxios from "../../../hooks/useAuthenticatedAxios";
import { object, string } from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import Modal from "../../molecules/Modal";
import Button from "../../molecules/Button";
import FormFieldContainer from "../../atoms/FormFieldContainer";
import FormField from "../../molecules/FormField";
import useUserInfo from "../../../hooks/api/useUserInfo";

type Props = {
  open: boolean;
  onClose: () => void;
};

type PresetCreate = {
  rpgLogo: string;
};

export default function ChangeUserLogoModal({ open, onClose }: Props) {
  const axios = useAuthenticatedAxios();
  const queryClient = useQueryClient();

  const { userInfo, isLoadingUser } = useUserInfo();

  const UserPresetSchema = object().shape({
    rpgLogo: string().required("A URL da imagem é obrigatória"),
  });

  async function handleSubmit(
    values: PresetCreate,
    helpers: FormikHelpers<PresetCreate>
  ) {
    try {
      await axios.put("/users", values);

      queryClient.invalidateQueries();
    } catch (error) {
      console.error("An error occurred");
    }

    helpers.setSubmitting(false);
    onClose();
  }

  if (isLoadingUser) {
    return <></>;
  }

  return (
    <Modal open={open} onClose={onClose} title="Atualizar logo do RPG">
      <Formik
        initialValues={{ rpgLogo: userInfo.rpgLogo }}
        onSubmit={handleSubmit}
        validationSchema={UserPresetSchema}
        validateOnBlur
        validateOnChange
      >
        {({ isSubmitting, errors, touched, isValid, dirty }) => (
          <Form>
            <div className="mb-10 flex items-center flex-col gap-4">
              <FormFieldContainer>
                <FormField
                  name="rpgLogo"
                  label="URL da logo do RPG"
                  type="text"
                  errors={errors}
                  touched={touched}
                />
              </FormFieldContainer>
              <Button
                loading={isSubmitting}
                className="w-full"
                disabled={!dirty || !isValid}
                type="submit"
              >
                Salvar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
