import { useEffect, useState } from "react";
import { useSwiper } from "swiper/react";

export const useSwiperReactive = () => {
  const swiper = useSwiper();

  // State to force a rerender.
  const [, setSignal] = useState({});

  useEffect(() => {
    if (swiper) {
      const forceRerender = () => {
        setSignal({});
      };

      swiper.on("slideChange", forceRerender);

      return () => {
        swiper.off("slideChange", forceRerender);
      };
    }
  }, [swiper]);

  return swiper;
};
