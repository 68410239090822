import { useQuery } from "@tanstack/react-query";
import useAuthenticatedAxios from "../useAuthenticatedAxios";

export default function usePresets() {
  const axios = useAuthenticatedAxios();

  function queryFn() {
    return axios.get("/presets");
  }

  const { data, isError, isLoading } = useQuery({
    queryKey: ["presets"],
    queryFn,
  });

  return {
    presets: data?.data || [],
    isError,
    isLoadingPresets: isLoading,
  };
}
