import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  imageUrl?: string;
  children?: ReactNode;
  className?: string;
};

export default function CharacterCard({
  imageUrl,
  children,
  className,
}: Props) {
  return (
    <article
      className={twMerge(
        "bg-slate-800 px-8 py-5 w-full flex justify-between items-center rounded-2xl hover:bg-slate-700 transition-colors",
        className
      )}
    >
      <img src={imageUrl} alt="Imagem do Personagem" className="h-32" />
      <div className="flex justify-center items-center gap-4 flex-col">
        {children}
      </div>
    </article>
  );
}
