import {
  ArrowLeftOnRectangleIcon,
  CheckIcon,
  LinkIcon,
  ArrowUpTrayIcon,
} from "@heroicons/react/24/outline";
import { DropdownMenu } from "../atoms/DropdownMenu";
import UserInitials from "../atoms/UserInitials";
import useAuthStore from "../../stores/useAuthStore";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { twMerge } from "tailwind-merge";

type Props = {
  userName: string;
  userId: number;
  onOpenChangeLogoModal: () => void;
};

export default function UserMenu({
  userName,
  userId,
  onOpenChangeLogoModal,
}: Props) {
  const logout = useAuthStore((store) => store.logout);

  const location = window.location.href;
  const parsedLocation =
    location + (location.charAt(location.length - 1) === "/" ? "" : "/");

  const { handleCopyClick, isCopied } = useCopyToClipboard(
    `${parsedLocation}initiative/${userId}`
  );

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <button className="transition-opacity hover:cursor-pointer hover:opacity-75">
          <UserInitials userName={userName} />
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content sideOffset={16} className="mr-4 w-32 min-w-[220px]">
        <DropdownMenu.Item onClick={() => logout()}>
          <ArrowLeftOnRectangleIcon className="h-5 w-5" />
          Logout
        </DropdownMenu.Item>
        <DropdownMenu.Item
          className={twMerge(isCopied && "text-indigo-300")}
          onClick={() => handleCopyClick()}
        >
          {isCopied ? (
            <CheckIcon className="h-5 w-5"></CheckIcon>
          ) : (
            <LinkIcon className="h-5 w-5" />
          )}
          Link da iniciativa
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={onOpenChangeLogoModal}>
          <ArrowUpTrayIcon className="h-5 w-5" />
          Trocar logo
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
