import { twMerge } from "tailwind-merge";
import "../../assets/styles/spinner.css";

type Props = {
  className?: string;
  divRef?: React.MutableRefObject<null>;
  color?: "white" | "indigo" | "slate";
  size?: "small" | "normal" | "large";
};

export default function Spinner({ color, size, className, divRef }: Props) {
  let sizeToRender = "";

  switch (size) {
    case "small":
      sizeToRender = "h-6 w-6";
      break;
    case "large":
      sizeToRender = "h-16 w-16";
      break;
    default:
      sizeToRender = "h-10 w-10";
      break;
  }

  let colorToRender = "";

  switch (color) {
    case "indigo":
      colorToRender = "stroke-indigo-600";
      break;
    case "slate":
      colorToRender = "stroke-slate-600";
      break;
    default:
      colorToRender = "stroke-white";
      break;
  }

  return (
    <div className={twMerge("spinner", className, sizeToRender)} ref={divRef}>
      <svg viewBox="0 0 100 100">
        <circle
          className={twMerge(colorToRender, "transition-all")}
          cx="50"
          cy="50"
          r="20"
        />
      </svg>
    </div>
  );
}
