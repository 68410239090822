import { useQueryClient } from "@tanstack/react-query";
import useAuthenticatedAxios from "../../../hooks/useAuthenticatedAxios";
import { object, string } from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import Modal from "../../molecules/Modal";
import Button from "../../molecules/Button";
import FormFieldContainer from "../../atoms/FormFieldContainer";
import FormField from "../../molecules/FormField";

type Props = {
  open: boolean;
  onClose: () => void;
};

type PresetCreate = {
  name: string;
  imageUrl: string;
};

export default function CreatePresetModal({ open, onClose }: Props) {
  const axios = useAuthenticatedAxios();
  const queryClient = useQueryClient();

  const UserPresetSchema = object().shape({
    name: string().min(1).required("O nome é obrigatório"),
    imageUrl: string().required("A URL da imagem é obrigatório"),
  });

  async function handleSubmit(
    values: PresetCreate,
    helpers: FormikHelpers<PresetCreate>
  ) {
    try {
      await axios.post("/presets", values);

      queryClient.invalidateQueries();
    } catch (error) {
      console.error("An error occurred");
    }

    helpers.setSubmitting(false);
    onClose();
  }

  return (
    <Modal open={open} onClose={onClose} title="Criar nova predefinição">
      <Formik
        initialValues={{ name: "", imageUrl: "" }}
        onSubmit={handleSubmit}
        validationSchema={UserPresetSchema}
        validateOnBlur
        validateOnChange
      >
        {({ isSubmitting, errors, touched, isValid, dirty, values }) => (
          <Form>
            <div className="mb-10 flex items-center flex-col gap-4">
              <FormFieldContainer>
                <FormField
                  name="name"
                  label="Nome do Personagem"
                  type="text"
                  errors={errors}
                  touched={touched}
                />
                <FormField
                  name="imageUrl"
                  label="Imagem"
                  type="text"
                  errors={errors}
                  touched={touched}
                />
                {values.imageUrl && (
                  <div className="flex justify-center">
                    <img
                      src={values.imageUrl}
                      alt="Preview"
                      className="text-white h-36"
                    />
                  </div>
                )}
              </FormFieldContainer>
              <Button
                loading={isSubmitting}
                className="w-full"
                disabled={!dirty || !isValid}
                type="submit"
              >
                Salvar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
