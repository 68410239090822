import { useNavigate } from 'react-router-dom';
import useIsAuthenticated from '../hooks/useIsAuthenticated';
import { useEffect } from 'react';

export default function useAuthenticatedRoute() {
  const authenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authenticated) {
      navigate('/login');
    }
  }, [authenticated, navigate]);
}
