import { ReactNode } from "react";
import { styled } from "react-tailwind-variants";

const StyledIconButton = styled("button", {
  base: "flex h-12 w-12 items-center justify-center rounded-full transition-all disabled:hover:bg-transparent outline-none active:opacity-90 cursor-pointer disabled:cursor-not-allowed disabled:hover:opacity-80 disabled:active:opacity-70",
  variants: {
    color: {
      indigo: "text-slate-100 hover:bg-indigo-600 disabled:text-slate-300",
      destructive: "text-slate-100 hover:bg-red-600 disabled:text-slate-500",
      slate: "text-slate-200 hover:bg-slate-800 disabled:text-slate-500",
    },
  },
  defaultVariants: {
    color: "indigo",
  },
});

type Props = {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  color?: "indigo" | "slate" | "destructive";
};

export default function IconButton({
  children,
  className,
  onClick,
  disabled,
  color,
}: Props) {
  return (
    <StyledIconButton
      type="button"
      onClick={onClick}
      disabled={disabled}
      color={color}
      className={className}
    >
      {children}
    </StyledIconButton>
  );
}
