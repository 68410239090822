import axios, { CreateAxiosDefaults } from 'axios';
import env from './env';

const config: CreateAxiosDefaults = {
  baseURL: env.API_URL,
  headers: {
    Authorization: '',
  },
};

const HttpClient = axios.create(config);

export default HttpClient;
