/* eslint-disable import/no-anonymous-default-export */
import HttpClient from "../libs/HttpClient";

export type RegisterPayload = {
  name: string;
  email: string;
  password: string;
};

class UsersService {
  async register(payload: RegisterPayload): Promise<any> {
    const response = await HttpClient.post("/users", payload);

    return response.data;
  }
}

export default new UsersService();
