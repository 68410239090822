import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import IconButton from "../atoms/IconButton";
import { useSwiper } from "swiper/react";

type Props = {
  isBeginning: boolean;
  isEnd: boolean;
};

export default function SliderNavigation({ isBeginning, isEnd }: Props) {
  const swiper = useSwiper();

  function handleNext() {
    swiper.slideNext();
  }

  function handlePrev() {
    swiper.slidePrev();
  }

  return (
    <div className="mb-4 flex">
      <IconButton disabled={isBeginning} onClick={handlePrev}>
        <ChevronLeftIcon className="h-6 w-6" />
      </IconButton>
      <IconButton disabled={isEnd} onClick={handleNext}>
        <ChevronRightIcon className="h-6 w-6" />
      </IconButton>
    </div>
  );
}
